@import url("https://fonts.googleapis.com/css2?family=Rampart+One&display=swap");

.navbar-main {
  background-color: #151618;
  box-shadow: 0px 0px 10px 2px;
}

.nav-link {
  color: #ffff !important;
  margin-left: 15px;
}

.services-dropdown {
  position: absolute;
  top: 50px;
  z-index: 1;
  background-color: #323335;
  border-radius: 10px;
  height: auto;
  width: 229px;
}

.services-dropdown li {
  padding: 0 20px 0 20px;
  text-align: left;
  text-decoration: none;
}

.services-dropdown li a {
  color: #ffff;
  text-decoration: none;
  font-weight: normal;
}

.services-dropdown li a:hover {
  background-color: #151618;
  padding: 10px;
  transition: 0.2s ease all;
  border-radius: 8px;
  color: #72abff;
}

#dropdown-toggle {
  display: contents;
}

#first {
  padding-top: 20px;
}
#second {
  padding-bottom: 10px;
}
@media (max-width: 323px) {
  .logo {
    height: 50px !important;
  }
  .navbar-toggler-icon {
    background-image: url(./graphics/toggle.png) !important;
    background-size: 15px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .services-dropdown {
    position: absolute;
    top: 88px;
    right: 107px;
    width: auto;
  }
}
@media (min-width: 450px) and (max-width: 767px) {
  .services-dropdown {
    position: absolute;
    top: 88px;
    right: 15px;
    width: auto;
  }
}
@media (min-width: 408px) and (max-width: 501px) {
  .services-dropdown {
    position: absolute;
    top: 100px;
    right: 40px;
    width: auto;
  }
  .services-dropdown li a {
    font-size: 10px;
  }
  .services-dropdown li {
    padding: 0 10px 0 10px;
    text-align: left;
    text-decoration: none;
  }
  #first {
    padding-top: 5px;
  }
  #second {
    padding-bottom: 5px;
  }
}
@media (min-width: 430px) and (max-width: 500px) {
  .services-dropdown {
    position: absolute;
    top: 80px;
    right: 50px;
    width: auto;
  }
  .services-dropdown li a {
    font-size: 10px;
  }
  .services-dropdown li {
    padding: 0 10px 0 10px;
    text-align: left;
    text-decoration: none;
  }
  #first {
    padding-top: 5px;
  }
  #second {
    padding-bottom: 5px;
  }
}
@media (min-width: 0px) and (max-width: 429px) {
  .services-dropdown {
    position: absolute;
    top: 80px;
    right: 0px;
    width: auto;
  }
  .services-dropdown li a {
    font-size: 10px;
  }
  .services-dropdown li {
    padding: 0 5px 0 5px;
    text-align: center;
    text-decoration: none;
  }
  #first {
    padding-top: 5px;
  }
  #second {
    padding-bottom: 5px;
  }
  .services-dropdown li a:hover {
    padding: 5px;
    transition: 0.2s ease all;
    border-radius: 8px;
  }
}

@media (min-width: 992px) {
  #nav1:after {
    content: "";
    position: absolute;
    background-color: #ffff;
    height: 3px;
    bottom: 14px;
    left: 20px;
    width: 0;
    transition: all ease-in 0.3s;
  }
  #nav1:hover:after {
    width: 50px;
  }

  #nav2:after {
    content: "";
    position: absolute;
    background-color: #ffff;
    height: 3px;
    bottom: 14px;
    left: 94px;
    width: 0;
    transition: all ease-in 0.3s;
  }
  #nav2:hover:after {
    width: 52px;
  }

  #nav3:after {
    content: "";
    position: absolute;
    background-color: #ffff;
    height: 3px;
    bottom: 14px;
    left: 171px;
    width: 0;
    transition: all ease-in 0.3s;
  }
  #nav3:hover:after {
    width: 65px;
  }

  /* #nav4:after {
    content: "";
    position: absolute;
    background-color: #ffff;
    height: 3px;
    bottom: 14px;
    left: 282px;
    width: 0;
    transition: all ease-in 0.3s;
  }
  #nav4:hover:after {
    width: 71px;
  } */
  #nav5:after {
    content: "";
    position: absolute;
    background-color: #ffff;
    height: 3px;
    bottom: 14px;
    left: 282px;
    width: 0;
    transition: all ease-in 0.3s;
  }
  #nav5:hover:after {
    width: 63px;
  }
}
.navbar-toggler {
  border: none !important;
  background: none !important;
}

@media (min-width: 322px) {
  .navbar-toggler-icon {
    background-image: url(./graphics/toggle.png) !important;
    background-size: 20px !important;
  }
}

.navbar-brand {
  margin-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
