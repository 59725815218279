#contact {
  background-image: url(./graphics/images/Banners/black-tech.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.feeds {
  padding: 5px;
}
