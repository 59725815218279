ul {
  list-style-type: disc;
  margin-left: 20px;
}

li {
  margin-bottom: 10px;
}

p {
  margin-top: 70px;
  text-align: justify;
  margin: 20px;
}

.services_banner {
  text-align: center;
  color: #ffff;
  padding: 130px;
}

.services_banner h1 {
  font-size: 60px;
}

@media (min-width: 463px) and (max-width: 768px) {
  .services_banner h1 {
    font-size: 40px;
  }
  .services_banner {
    padding: 80px;
  }
}
@media (max-width: 462px) {
  .services_banner h1 {
    font-size: 30px;
  }
  .services_banner {
    padding: 65px;
  }
}
.services-content {
  margin-top: 70px;
}

.services-content h3 {
  margin-bottom: 30px;
}

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.services_banner {
  background-image: url(../graphics/images/Banners/black-tech.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

/* #sd {
  background-image: url(../graphics/images/Banners/black-tech.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#ts {
  background-image: url(../graphics/images/Banners/black-tech.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
