.name-strong {
  color: #0d47a1;
}

#header .header-img {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (min-width: 992px) {
  #header {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .header-img {
    margin-top: 20px;
  }
}
