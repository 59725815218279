.card {
  height: 100% !important;
  padding: 30px;
  box-shadow: #505257 0px 6px 16px -8px;
}

.card-body {
  padding: 0 !important;
}

.card img {
  margin-bottom: 9px;
}

.card-text {
  text-align: center;
}
